import * as Sentry from '@sentry/gatsby';

if (process.env.GATSBY_EH_ENV === 'prod') {
    Sentry.init({
        dsn: 'https://2da199f1a8644a77a9973547ff9d44f7@o978409.ingest.sentry.io/4505075492585472',
        environment: process.env.GATSBY_EH_ENV,
        tracesSampleRate: 0.0,
        sampleRate: 0.5,
        ignoreErrors: [/.*Minified React error #.*/, /.*tealium.*/, /.*\[alloy\].*/],
    });
} else {
    if (typeof window !== 'undefined') {
        const SentryMock = {
            addBreadcrumb: (crumb: any) => {
                const level: string = crumb ? crumb.level : null;
                if (/info/i.test(level)) {
                    console.info(crumb);
                } else if (/warn/i.test(level)) {
                    console.warn(crumb);
                } else if (/error/i.test(level)) {
                    console.error(crumb);
                } else {
                    console.log(crumb);
                }
            },
            captureEvent: () => console.log('mocking Sentry captureEvent fn'),
            captureException: () => console.log('mocking Sentry captureException fn'),
            captureMessage: () => console.log('mocking Sentry captureMessage fn'),
            configureScope: () => console.log('mocking Sentry configureScope fn'),
            forceLoad: () => console.log('mocking Sentry forceLoad fn'),
            init: () => console.log('mocking Sentry init fn'),
            onLoad: () => console.log('mocking Sentry onLoad fn'),
            showReportDialog: () => console.log('mocking Sentry showReportDialog fn'),
            withScope: () => console.log('mocking Sentry withScope fn'),
        };

        Object.defineProperty(window, 'Sentry', {
            writable: false,
            value: SentryMock,
        });
    }
}
