import { Script } from 'gatsby';

import { IPageContext } from '~globals/interfaces';

export declare type ThirdPartyResourcesProps = {
    pageContext: IPageContext;
};

const ThirdPartyResources = ({ pageContext }: ThirdPartyResourcesProps) => {
    const adobeUrl = process.env.GATSBY_LAUNCH_SCRIPT_URL;

    return (
        <>
            { 'slug' in pageContext && pageContext.slug === 'online' ? (
                // do not use Script API to avoid slowing down AB test on this page
                <script key="launch" async type="text/javascript" src={adobeUrl} />
            ) : (
                <Script src={adobeUrl} async />
            )}
            <Script src="https://app-ab41.marketo.com/js/forms2/js/forms2.min.js" async />
            <Script src="https://munchkin.marketo.net/munchkin.js" async />
            <Script src="https://js.sentry-cdn.com/2da199f1a8644a77a9973547ff9d44f7.min.js" crossOrigin="anonymous" async />
        </>
    )
};

export default ThirdPartyResources;
