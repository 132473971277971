import { setGlobalTokens } from '@ehi/styles';

import './src/css/index.css';
import './src/css/template-sutter.css';

import wrapWithPageData from './wrap-with-pagedata';
import wrapWithProvider from './wrap-with-provider';

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = wrapWithPageData;

setGlobalTokens();

const addScript = () => {
    const globalScripts = document.createElement('script');
    globalScripts.innerHTML = `
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${process.env.GATSBY_HOT_JAR_ID},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

        setTimeout(function () {
            var __dcid = __dcid || [];
            __dcid.push({ cid: 'DigiCertClickID_onwANETs', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_1', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_2', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_3', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_4', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_5', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_6', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_7', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_8', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_9', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_10', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_11', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_12', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_13', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_14', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_15', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_16', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_17', tag: 'onwANETs' });
            __dcid.push({ cid: 'DigiCertClickID_onwANETs_18', tag: 'onwANETs' });
            window.__dcid = __dcid;

            (function(){
                var cid=document.createElement("script");
                cid.async=true;
                cid.src="//seal.digicert.com/seals/cascade/seal.min.js";
                var s = document.getElementsByTagName("script");
                var ls = s[(s.length - 1)];
                ls.parentNode.insertBefore(cid, ls.nextSibling);
            }());
        },1500);
        !function (e, v, o) { e[v] || (e[v] = [], e[v].x = e[v].push, e[v].push = function (a, b) { this.x([a, b]) }) }(window, "_waEvents");
        `;
    document.head.appendChild(globalScripts);
};

export const onClientEntry = () => {
    window.onload = () => {
        addScript();
    };
};
