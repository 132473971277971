const constants = Object.freeze({
    DESKTOP_BREAKPOINT: 992,
    TABLET_BREAKPOINT: 768,
    DEFAULT_ALLIANCE_ID: 'Med34728',
    DEFAULT_SEO_ALLIANCE_ID: 'Med37949',
    DEFAULT_PHONE: '877-543-6619',
    EHM_TRACKING_PARAMETERS: [
        'showPdpOnly',
        'hideContact',
        'leadFormEnabled',
        'hideEnrollPage',
        'defaultThrottle',
        'aep2020',
        'planYear',
        'isShowPlanYearElement',
        'previewPeriod',
        'leadFormEmbed',
        'leadForm',
        'defaultOpen',
        'detailCompareRedesign',
        'planUnitRedesign',
        'medicarecom',
        'mobileRedesign',
        'msType',
        'personalCode',
        'n2m',
        '_ga',
        'adobe_mc',
        '_gac',
    ] as EhmTrackingParameters[],
    OTHER_TRACKING_PARAMETERS: [
        'lastV1Action',
        'type',
        'sid',
        'pid',
        'leadId',
        'campaign',
    ] as OtherTrackingParameters[],
    CARRIER_ID: {
        aetna: '6:3',
        anthem: '6:113',
        cigna: '6:126',
        humana: '6:66',
        kaiser: '6:70',
        uhc: '6:108',
        wellcare: '6:124',
    },
    // to be updated annually before AEP:
    EXCLUDED_COUNTIES: [
        'SUTTER, CA',
        'PRINCE OF WALES HYDER, AK',
        'TUOLUMNE, CA',
        'CEIBA, PR',
        'BEAR LAKE, ID',
        'CHERRY, NE',
        'DENALI, AK',
        'MONTGOMERY, MD',
        'RENVILLE, ND',
        'WICOMICO, MD',
        'MEAGHER, MT',
        'GUANICA, PR',
        'FLORIDA, PR',
        'GOVE, KS',
        'BEAVER, UT',
        'MEADE, KS',
        'WASHINGTON, OK',
        'HOONAH ANGOON, AK',
        'QUEEN ANNES, MD',
        'BROWN, NE',
        'YUKON KOYUKUK, AK',
        'LOIZA, PR',
        'RINCON, PR',
        'CHEYENNE, KS',
        'WASCO, OR',
        'SISKIYOU, CA',
        'ANNE ARUNDEL, MD',
        'MOFFAT, CO',
        'COLLINGSWORTH, TX',
        'HORMIGUEROS, PR',
        'CIALES, PR',
        'HARNEY, OR',
        'GRAY, KS',
        'GOLDEN VALLEY, ND',
        'AIBONITO, PR',
        'DAGGETT, UT',
        'FALLON, MT',
        'GILLIAM, OR',
        'FORD, KS',
        'LINCOLN, NV',
        'OCHILTREE, TX',
        'LANE, KS',
        'DOUGLAS, WA',
        'CALVERT, MD',
        'JACKSON, SD',
        'BETHEL, AK',
        'YAUCO, PR',
        'BILLINGS, ND',
        'DILLINGHAM, AK',
        'VALLEY, MT',
        'SAN JUAN, UT',
        'HUMACAO, PR',
        'HOWARD, MD',
        'GARRETT, MD',
        'GRANT, SD',
        'GRANT, KS',
        'CLARK, KS',
        'DEL NORTE, CA',
        'CUSTER, SD',
        'LAJAS, PR',
        'MINERAL, CO',
        'PETERSBURG, AK',
        'STANTON, KS',
        'SAN BENITO, CA',
        'STEVENS, KS',
        'MONTEREY, CA',
        'CANOVANAS, PR',
        'ESMERALDA, NV',
        'LAS PIEDRAS, PR',
        'LASSEN, CA',
        'GRAND ISLE, VT',
        'ALLEGANY, MD',
        'SOMERSET, MD',
        'CROCKETT, TX',
        'NOME, AK',
        'JUAB, UT',
        'TRUJILLO ALTO, PR',
        'RED WILLOW, NE',
        'VIEQUES, PR',
        'FAULK, SD',
        'TOWNER, ND',
        'CHELAN, WA',
        'MELLETTE, SD',
        'DAWSON, MT',
        'SHERMAN, OR',
        'YAKUTAT, AK',
        'HOOKER, NE',
        'GLENN, CA',
        'PITKIN, CO',
        'DAWES, NE',
        'PENNINGTON, SD',
        'FRANKLIN, VT',
        'PONDERA, MT',
        'SEWARD, KS',
        'CARROLL, MD',
        'CATANO, PR',
        'HODGEMAN, KS',
        'TRINITY, CA',
        'CUSTER, ID',
        'AGUADILLA, PR',
        'ADAMS, ND',
        'REDWOOD, MN',
        'JUNEAU, AK',
        'PIERCE, ND',
        'CHARLES, MD',
        'GUAYNABO, PR',
        'YELLOW MEDICINE, MN',
        'HUMBOLDT, NV',
        'CHILDRESS, TX',
        'GRAHAM, KS',
        'POWER, ID',
        'FAJARDO, PR',
        'MOCA, PR',
        'COPPER RIVER, AK',
        'RICHLAND, MT',
        'PROWERS, CO',
        'CIDRA, PR',
        'TODD, SD',
        'GREELEY, KS',
        'ZIEBACH, SD',
        'SAN GERMAN, PR',
        'HARPER, OK',
        'BRISTOL BAY, AK',
        'CARIBOU, ID',
        'FALL RIVER, SD',
        'COLUSA, CA',
        'RIO BLANCO, CO',
        'ARECIBO, PR',
        'OURAY, CO',
        'STANLEY, SD',
        'MORTON, KS',
        'SIERRA, CA',
        'MINERAL, MT',
        'POTTER, SD',
        'LAWRENCE, SD',
        'EDMUNDS, SD',
        'THOMAS, KS',
        'OGLALA LAKOTA, SD',
        'ADJUNTAS, PR',
        'MARICAO, PR',
        'CECIL, MD',
        'SCURRY, TX',
        'PERKINS, SD',
        'MARIPOSA, CA',
        'WHITE PINE, NV',
        'HARFORD, MD',
        'MCCONE, MT',
        'CULEBRA, PR',
        'LEMHI, ID',
        'SAINT MARYS, MD',
        'ROUTT, CO',
        'SULLY, SD',
        'COAMO, PR',
        'GUAYANILLA, PR',
        'ARTHUR, NE',
        'CLATSOP, OR',
        'GARFIELD, MT',
        'HAMILTON, KS',
        'GRANT, NE',
        'HYDE, SD',
        'MORROW, OR',
        'HUMBOLDT, CA',
        'DEUEL, NE',
        'ONEIDA, ID',
        'KETCHIKAN GATEWAY, AK',
        'SHERIDAN, WY',
        'SHASTA, CA',
        'CAROLINA, PR',
        'ROOSEVELT, MT',
        'LAKE AND PENINSULA, AK',
        'UMATILLA, OR',
        'BURKE, ND',
        'CAMUY, PR',
        'WORCESTER, MD',
        'BAKER, OR',
        'YABUCOA, PR',
        'ELKO, NV',
        'MCPHERSON, SD',
        'EUREKA, NV',
        'MOROVIS, PR',
        'HAINES, AK',
        'PIUTE, UT',
        'ELLIS, KS',
        'WALLOWA, OR',
        'SAN MIGUEL, CO',
        'NANTUCKET, MA',
        'ALPINE, CA',
        'PLUMAS, CA',
        'MUSSELSHELL, MT',
        'COTTONWOOD, MN',
        'CHITTENDEN, VT',
        'THOMAS, NE',
        'LARES, PR',
        'FAIRBANKS NORTH STAR, AK',
        'GARFIELD, CO',
        'NORTON, KS',
        'BENEWAH, ID',
        'NESS, KS',
        'LIBERTY, MT',
        'KODIAK ISLAND, AK',
        'BARCELONETA, PR',
        'CURRY, OR',
        'DEWEY, SD',
        'GARDEN, NE',
        'SKAGWAY, AK',
        'MCPHERSON, NE',
        'LUQUILLO, PR',
        'WOODWARD, OK',
        'DANIELS, MT',
        'LYMAN, SD',
        'GOLDEN VALLEY, MT',
        'MEADE, SD',
        'VEGA BAJA, PR',
        'ALEUTIANS WEST, AK',
        'ARROYO, PR',
        'PRAIRIE, MT',
        'RIO GRANDE, CO',
        'KEARNY, KS',
        'MENDOCINO, CA',
        'ANASCO, PR',
        'AGUAS BUENAS, PR',
        'FREMONT, ID',
        'PETROLEUM, MT',
        'HARDING, SD',
        'CABO ROJO, PR',
        'LIPSCOMB, TX',
        'WHEELER, TX',
        'INYO, CA',
        'SOUTHEAST FAIRBANKS, AK',
        'MINERAL, NV',
        'NORTHWEST ARCTIC, AK',
        'CAROLINE, MD',
        'MANATI, PR',
        'CUSTER, MT',
        'SCOTT, KS',
        'SALINAS, PR',
        'JUANA DIAZ, PR',
        'HOOD RIVER, OR',
        'JAYUYA, PR',
        'PHILLIPS, MT',
        'HEMPHILL, TX',
        'LAKE, CA',
        'BUTTE, CA',
        'MATANUSKA SUSITNA, AK',
        'BOTTINEAU, ND',
        'COMERIO, PR',
        'COMANCHE, KS',
        'OROCOVIS, PR',
        'HATILLO, PR',
        'MAUNABO, PR',
        'HUGHES, SD',
        'BIG STONE, MN',
        'MONO, CA',
        'OKANOGAN, WA',
        'QUEBRADILLAS, PR',
        'MODOC, CA',
        'LEWIS, ID',
        'NAGUABO, PR',
        'UTUADO, PR',
        'CLEARWATER, ID',
        'TETON, ID',
        'LOGAN, KS',
        'TERRELL, TX',
        'WHEELER, OR',
        'MAYAGUEZ, PR',
        'VILLALBA, PR',
        'ANCHORAGE, AK',
        'COTTLE, TX',
        'CARTER, MT',
        'CALAVERAS, CA',
        'CLARK, ID',
        'JACKSON, MN',
        'KENAI PENINSULA, AK',
        'HASKELL, KS',
        'LAS MARIAS, PR',
        'SEDGWICK, CO',
        'TALBOT, MD',
        'NEVADA, CA',
        'WASHINGTON, MD',
        'DUNDY, NE',
        'ELLIS, OK',
        'TOA ALTA, PR',
        'SAN JUAN, PR',
        'SAN SEBASTIAN, PR',
        'GUNNISON, CO',
        'FREDERICK, MD',
        'MILLARD, UT',
        'TEHAMA, CA',
        'SAN JUAN, CO',
        'SITKA, AK',
        'CAGUAS, PR',
        'KALAWAO, HI',
        'SABANA GRANDE, PR',
        'BALTIMORE CITY, MD',
        'SHERIDAN, NE',
        'BAYAMON, PR',
        'STEVENS, MN',
        'HAND, SD',
        'SANTA BARBARA, CA',
        'BLAINE, MT',
        'BENNETT, SD',
        'PONCE, PR',
        'DORADO, PR',
        'DOLORES, CO',
        'GARFIELD, UT',
        'WHEATLAND, MT',
        'SHERMAN, KS',
        'KANE, UT',
        'LAC QUI PARLE, MN',
        'SAN LORENZO, PR',
        'TRIPP, SD',
        'TOA BAJA, PR',
        'LYON, MN',
        'GRANT, OR',
        'ROSEBUD, MT',
        'POWDER RIVER, MT',
        'BUTTE, ID',
        'HETTINGER, ND',
        'PATILLAS, PR',
        'DUNN, ND',
        'VEGA ALTA, PR',
        'MONROE, FL',
        'SANTA ISABEL, PR',
        'GUAYAMA, PR',
        'KEYA PAHA, NE',
        'RIO GRANDE, PR',
        'ISABELA, PR',
        'JUDITH BASIN, MT',
        'BOX BUTTE, NE',
        'RAWLINS, KS',
        'WAYNE, UT',
        'CAYEY, PR',
        'JONES, SD',
        'KUSILVAK, AK',
        'DORCHESTER, MD',
        'HAAKON, SD',
        'BARRANQUITAS, PR',
        'PERSHING, NV',
        'WIBAUX, MT',
        'DUKES, MA',
        'LANDER, NV',
        'BOWMAN, ND',
        'PENUELAS, PR',
        'CONTRA COSTA, CA',
        'GLACIER, MT',
        'HINSDALE, CO',
        'GURABO, PR',
        'YUBA, CA',
        'FINNEY, KS',
        'PARMER, TX',
        'TREASURE, MT',
        'ROLETTE, ND',
        'LAKE, OR',
        'WICHITA, KS',
        'COROZAL, PR',
        'SHERIDAN, KS',
        'UNION, OR',
        'MCKENZIE, ND',
        'LINCOLN, OR',
        'WRANGELL, AK',
        'PRINCE GEORGES, MD',
        'DIVIDE, ND',
        'CHUGACH, AK',
        'EMERY, UT',
        'NORTH SLOPE, AK',
        'CAVALIER, ND',
        'TILLAMOOK, OR',
        'BUFFALO, SD',
        'KENT, MD',
        'SHERIDAN, MT',
        'KLAMATH, OR',
        'JUNCOS, PR',
        'TOOLE, MT',
        'TREGO, KS',
        'NARANJITO, PR',
        'CHASE, NE',
        'DECATUR, KS',
        'BUTTE, SD',
        'WALLACE, KS',
        'ALEUTIANS EAST, AK',
        'SLOPE, ND',
        'BALTIMORE, MD',
        'AGUADA, PR',
    ],
    MS_STATES_WITH_DYNAMIC_HERO: [
        'GA',
        'SC',
        'OK',
    ],
});

export default constants;
